
module DBS.AutoComplete
{
    const rateLimitDelay = 100;
    let ajax: XMLHttpRequest;
    let timeout: number;

    function initiateSearch(ev: KeyboardEvent)
    {
        if (typeof (timeout) !== "undefined")
        {
            clearTimeout(timeout);
        }

        if (typeof (ajax) !== "undefined" && ajax.readyState !== XMLHttpRequest.DONE)
        {
            ajax.abort();
        }

        timeout = setInterval(() => sendSearch(ev.target), rateLimitDelay);
    }

    function sendSearch(ctrl: EventTarget)
    {
        if (typeof (timeout) !== "undefined")
        {
            clearTimeout(timeout);
        }

        const inputCtrl = ctrl as HTMLInputElement;

        if (inputCtrl.value.length < 4)
        {
            return;
        }

        ajax = new XMLHttpRequest();
        ajax.addEventListener("readystatechange", (ev: ProgressEvent) => completeSearch(ev, ctrl));
        ajax.open("GET", `/api/autocomplete/${inputCtrl.value}/`, true);
        ajax.send();
    }

    function completeSearch(ev: ProgressEvent, ctrl: EventTarget)
    {
        if (ajax.readyState !== XMLHttpRequest.DONE || ajax.status !== 200)
        {
            return;
        }

        const items = JSON.parse(ajax.responseText) as string[];


        const autoWrapper = (ctrl as HTMLInputElement).parentElement as HTMLDivElement;
        const oldItems = autoWrapper.getElementsByTagName("ul");

        for (let i = 0; i < oldItems.length; i++)
        {
            oldItems[i].remove();
        }

        if (items.length === 0)
        {
            return;
        }

        const autoList = document.createElement("ul") as HTMLUListElement;

        for (let i = 0; i < items.length; i++)
        {
            const listItem = document.createElement("li") as HTMLLIElement;
            listItem.innerText = items[i];
            listItem.addEventListener("click", selectSearch);
            autoList.appendChild(listItem);
        }

        autoWrapper.appendChild(autoList);
    }

    function selectSearch(ev: Event)
    {
        const item = ev.target as HTMLLIElement;
        const widget = recurseUntilClass(item, "search-auto");
        const box = widget.getElementsByTagName("input")[0] as HTMLInputElement;

        box.value = item.innerText;

        const oldItems = widget.getElementsByTagName("ul");
        for (let i = 0; i < oldItems.length; i++)
        {
            oldItems[i].remove();
        }
    }

    function recurseUntilClass(elem: HTMLElement, cssClass: string)
    {
        if (elem == null)
        {
            return null;
        }

        if (elem.classList.contains(cssClass))
        {
            return elem;
        }

        return recurseUntilClass(elem.parentElement, cssClass);
    }

    export function Initialise()
    {
        const searchWidgets = document.getElementsByClassName("search-auto");

        for (let i = 0; i < searchWidgets.length; i++)
        {
            const widget = searchWidgets[i] as HTMLDivElement;
            const box = widget.getElementsByTagName("input")[0] as HTMLInputElement;

            box.addEventListener("keyup", initiateSearch);
        }
    }
}

window.addEventListener("load", DBS.AutoComplete.Initialise);